import Button from "../components/Button";

const PlansModal = () => {
  return (
    <div className="bg-white px-4 py-4 h-full mx-4 lg:mx-0">
      <div className="flex flex-col gap-2 mb-3">
        <h2 className="text-black-500 text-xl">Payment method</h2>
        <p className="text-gray-500 text-sm">
          Select your preferred payment method
        </p>
      </div>
      <form className="h-auto lg:h-[40vh] overflow-y-scroll hide-scrollbar">
        <div className="flex flex-col gap-2 mb-3">
          <div className="relative w-full">
            <div className="absolute inset-y-0 start-0 flex flex-col gap-2 px-2 pt-2  items-center">
              <svg
                width="20"
                height="18"
                viewBox="0 0 30 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22 6.19625V5.5C22 2.365 17.2712 0 11 0C4.72875 0 0 2.365 0 5.5V10.5C0 13.1112 3.28125 15.1863 8 15.8075V16.5C8 19.635 12.7288 22 19 22C25.2712 22 30 19.635 30 16.5V11.5C30 8.9125 26.8225 6.835 22 6.19625ZM28 11.5C28 13.1525 24.1512 15 19 15C18.5338 15 18.0712 14.9838 17.615 14.9538C20.3112 13.9713 22 12.375 22 10.5V8.2175C25.7337 8.77375 28 10.2838 28 11.5ZM8 13.7812V10.8075C8.99472 10.9371 9.99687 11.0014 11 11C12.0031 11.0014 13.0053 10.9371 14 10.8075V13.7812C13.0068 13.928 12.004 14.0011 11 14C9.99598 14.0011 8.99324 13.928 8 13.7812ZM20 8.74125V10.5C20 11.5487 18.4487 12.675 16 13.3587V10.4375C17.6138 10.0463 18.98 9.46375 20 8.74125ZM11 2C16.1512 2 20 3.8475 20 5.5C20 7.1525 16.1512 9 11 9C5.84875 9 2 7.1525 2 5.5C2 3.8475 5.84875 2 11 2ZM2 10.5V8.74125C3.02 9.46375 4.38625 10.0463 6 10.4375V13.3587C3.55125 12.675 2 11.5487 2 10.5ZM10 16.5V15.9788C10.3287 15.9913 10.6613 16 11 16C11.485 16 11.9587 15.9837 12.4237 15.9562C12.9403 16.1412 13.4665 16.2981 14 16.4263V19.3587C11.5513 18.675 10 17.5487 10 16.5ZM16 19.7812V16.8C16.9944 16.9337 17.9967 17.0005 19 17C20.0031 17.0014 21.0053 16.9371 22 16.8075V19.7812C20.0106 20.0729 17.9894 20.0729 16 19.7812ZM24 19.3587V16.4375C25.6138 16.0462 26.98 15.4637 28 14.7412V16.5C28 17.5487 26.4487 18.675 24 19.3587Z"
                  fill="#343330"
                />
              </svg>
              <span className="text-xs">Crypto</span>
            </div>
            <input
              name="crypto"
              className="w-full px-4 py-5 border border-gray-600 rounded-lg outline-none"
            />
          </div>
          <div className="relative w-full">
            <div className="absolute inset-y-0 start-0 flex flex-col gap-2 px-2 pt-2  items-center">
              <svg
                width="22"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M25 21C24.9983 22.5908 24.3657 24.116 23.2408 25.2408C22.116 26.3657 20.5908 26.9983 19 27H17V29C17 29.2652 16.8946 29.5196 16.7071 29.7071C16.5196 29.8946 16.2652 30 16 30C15.7348 30 15.4804 29.8946 15.2929 29.7071C15.1054 29.5196 15 29.2652 15 29V27H13C11.4092 26.9983 9.88405 26.3657 8.75919 25.2408C7.63433 24.116 7.00165 22.5908 7 21C7 20.7348 7.10536 20.4804 7.29289 20.2929C7.48043 20.1054 7.73478 20 8 20C8.26522 20 8.51957 20.1054 8.70711 20.2929C8.89464 20.4804 9 20.7348 9 21C9 22.0609 9.42143 23.0783 10.1716 23.8284C10.9217 24.5786 11.9391 25 13 25H19C20.0609 25 21.0783 24.5786 21.8284 23.8284C22.5786 23.0783 23 22.0609 23 21C23 19.9391 22.5786 18.9217 21.8284 18.1716C21.0783 17.4214 20.0609 17 19 17H14C12.4087 17 10.8826 16.3679 9.75736 15.2426C8.63214 14.1174 8 12.5913 8 11C8 9.4087 8.63214 7.88258 9.75736 6.75736C10.8826 5.63214 12.4087 5 14 5H15V3C15 2.73478 15.1054 2.48043 15.2929 2.29289C15.4804 2.10536 15.7348 2 16 2C16.2652 2 16.5196 2.10536 16.7071 2.29289C16.8946 2.48043 17 2.73478 17 3V5H18C19.5908 5.00165 21.116 5.63433 22.2408 6.75919C23.3657 7.88405 23.9983 9.40921 24 11C24 11.2652 23.8946 11.5196 23.7071 11.7071C23.5196 11.8946 23.2652 12 23 12C22.7348 12 22.4804 11.8946 22.2929 11.7071C22.1054 11.5196 22 11.2652 22 11C22 9.93913 21.5786 8.92172 20.8284 8.17157C20.0783 7.42143 19.0609 7 18 7H14C12.9391 7 11.9217 7.42143 11.1716 8.17157C10.4214 8.92172 10 9.93913 10 11C10 12.0609 10.4214 13.0783 11.1716 13.8284C11.9217 14.5786 12.9391 15 14 15H19C20.5908 15.0017 22.116 15.6343 23.2408 16.7592C24.3657 17.884 24.9983 19.4092 25 21Z"
                  fill="#343330"
                />
              </svg>

              <span className="text-xs pb-2">Stripe</span>
            </div>
            <input
              name="stripe"
              className="w-full px-4 py-5 border border-gray-600 rounded-lg outline-none"
            />
          </div>
        </div>
        <div className="mb-10">
          <Button className="flex custom-bg  justify-center items-center w-full bg-white  text-white border border-gray-900 text-xs px-2 py-3 lg:text-sm lg:px-5 lg:py-2 rounded-xl">
            Continue
          </Button>
        </div>
      </form>
    </div>
  );
};

export default PlansModal;

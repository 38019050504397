const AdminUsersIcon = () => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.774 18.3857C21.5233 18.3857 22.1193 17.9142 22.6545 17.2549C23.7499 15.9052 21.9513 14.8266 21.2654 14.2983C20.568 13.7614 19.7894 13.4572 19 13.3857M18 11.3857C19.3807 11.3857 20.5 10.2665 20.5 8.88574C20.5 7.50503 19.3807 6.38574 18 6.38574" stroke="white" stroke-width="1.5" stroke-linecap="round" />
            <path d="M3.22596 18.3857C2.47666 18.3857 1.88067 17.9142 1.34555 17.2549C0.250089 15.9052 2.04867 14.8266 2.73465 14.2983C3.43197 13.7614 4.21058 13.4572 5 13.3857M5.5 11.3857C4.11929 11.3857 3 10.2665 3 8.88574C3 7.50503 4.11929 6.38574 5.5 6.38574" stroke="white" stroke-width="1.5" stroke-linecap="round" />
            <path d="M8.0838 15.4969C7.06203 16.1287 4.38299 17.4188 6.0147 19.0332C6.81178 19.8218 7.69952 20.3857 8.81563 20.3857H15.1844C16.3005 20.3857 17.1882 19.8218 17.9853 19.0332C19.617 17.4188 16.938 16.1287 15.9162 15.4969C13.5201 14.0153 10.4799 14.0153 8.0838 15.4969Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M15.5 7.88574C15.5 9.81874 13.933 11.3857 12 11.3857C10.067 11.3857 8.5 9.81874 8.5 7.88574C8.5 5.95275 10.067 4.38574 12 4.38574C13.933 4.38574 15.5 5.95275 15.5 7.88574Z" stroke="white" stroke-width="1.5" />
        </svg>
    )
}

export default AdminUsersIcon
const AdminDashboardIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 7C4 6.06812 4 5.60218 4.15224 5.23463C4.35523 4.74458 4.74458 4.35523 5.23463 4.15224C5.60218 4 6.06812 4 7 4C7.93188 4 8.39782 4 8.76537 4.15224C9.25542 4.35523 9.64477 4.74458 9.84776 5.23463C10 5.60218 10 6.06812 10 7C10 7.93188 10 8.39782 9.84776 8.76537C9.64477 9.25542 9.25542 9.64477 8.76537 9.84776C8.39782 10 7.93188 10 7 10C6.06812 10 5.60218 10 5.23463 9.84776C4.74458 9.64477 4.35523 9.25542 4.15224 8.76537C4 8.39782 4 7.93188 4 7Z" stroke="currentColor" stroke-width="1.5" />
            <path d="M14 7C14 6.06812 14 5.60218 14.1522 5.23463C14.3552 4.74458 14.7446 4.35523 15.2346 4.15224C15.6022 4 16.0681 4 17 4C17.9319 4 18.3978 4 18.7654 4.15224C19.2554 4.35523 19.6448 4.74458 19.8478 5.23463C20 5.60218 20 6.06812 20 7C20 7.93188 20 8.39782 19.8478 8.76537C19.6448 9.25542 19.2554 9.64477 18.7654 9.84776C18.3978 10 17.9319 10 17 10C16.0681 10 15.6022 10 15.2346 9.84776C14.7446 9.64477 14.3552 9.25542 14.1522 8.76537C14 8.39782 14 7.93188 14 7Z" stroke="currentColor" stroke-width="1.5" />
            <path d="M4 17C4 16.0681 4 15.6022 4.15224 15.2346C4.35523 14.7446 4.74458 14.3552 5.23463 14.1522C5.60218 14 6.06812 14 7 14C7.93188 14 8.39782 14 8.76537 14.1522C9.25542 14.3552 9.64477 14.7446 9.84776 15.2346C10 15.6022 10 16.0681 10 17C10 17.9319 10 18.3978 9.84776 18.7654C9.64477 19.2554 9.25542 19.6448 8.76537 19.8478C8.39782 20 7.93188 20 7 20C6.06812 20 5.60218 20 5.23463 19.8478C4.74458 19.6448 4.35523 19.2554 4.15224 18.7654C4 18.3978 4 17.9319 4 17Z" stroke="currentColor" stroke-width="1.5" />
            <path d="M14 17C14 16.0681 14 15.6022 14.1522 15.2346C14.3552 14.7446 14.7446 14.3552 15.2346 14.1522C15.6022 14 16.0681 14 17 14C17.9319 14 18.3978 14 18.7654 14.1522C19.2554 14.3552 19.6448 14.7446 19.8478 15.2346C20 15.6022 20 16.0681 20 17C20 17.9319 20 18.3978 19.8478 18.7654C19.6448 19.2554 19.2554 19.6448 18.7654 19.8478C18.3978 20 17.9319 20 17 20C16.0681 20 15.6022 20 15.2346 19.8478C14.7446 19.6448 14.3552 19.2554 14.1522 18.7654C14 18.3978 14 17.9319 14 17Z" stroke="currentColor" stroke-width="1.5" />
        </svg>


    )
}


export default AdminDashboardIcon
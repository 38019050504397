import Logo from "../assets/lanepact-logo.png";
import LogoText from "../assets/lanepact-logo-text.png";

const Footer = () => {
  return (
    <div className="footer relative bottom-[0]">
      <div className="lg:mx-auto  py-8 px-6 lg:px-12">
        <div className="flex items-center gap-2">
          <img src={Logo} alt="logo" width={20} height={20} />
          <img src={LogoText} alt="logo" width={80} height={80} />
        </div>

        <div className="flex flex-col lg:flex-row items-start justify-between mt-4 pb-8">
          <div className="flex flex-col lg:flex-row gap-2 lg:items-center mb-4 lg:mb-0">
            <div className="flex gap-3 mb-2 lg:mb-0">
              <a href="https://t.me/+iw2jh3VaeSg4MzBk" target="blank">
                <span className="flex items-center justify-center">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.0229 24.0063H11.9701C5.36984 24.0063 0 18.6349 0 12.0328V11.9799C0 5.37777 5.36984 0.00634766 11.9701 0.00634766H12.0229C18.6231 0.00634766 23.9929 5.37777 23.9929 11.9799V12.0328C23.9929 18.6349 18.6231 24.0063 12.0229 24.0063ZM11.9701 0.818723C5.81732 0.818723 0.812136 5.82538 0.812136 11.9799V12.0328C0.812136 18.1873 5.81732 23.194 11.9701 23.194H12.0229C18.1756 23.194 23.1808 18.1873 23.1808 12.0328V11.9799C23.1808 5.82538 18.1756 0.818723 12.0229 0.818723H11.9701Z"
                      fill="#383D38"
                    />
                    <path
                      d="M4.79645 11.6104C4.82893 11.5941 4.86143 11.5787 4.8931 11.5641C5.44373 11.309 6.00167 11.0701 6.5588 10.8313C6.58885 10.8313 6.63919 10.7964 6.66761 10.785C6.71065 10.7663 6.7537 10.7484 6.79675 10.7298C6.87958 10.694 6.96243 10.6591 7.04445 10.6233C7.21013 10.5526 7.37498 10.482 7.54066 10.4113C7.8712 10.27 8.20173 10.1286 8.53227 9.98643C9.19335 9.70373 9.85526 9.42019 10.5163 9.13749C11.1774 8.85478 11.8393 8.57126 12.5004 8.28856C13.1615 8.00585 13.8233 7.72234 14.4844 7.43963C15.1455 7.15692 15.8074 6.87341 16.4685 6.5907C16.6155 6.52734 16.7747 6.4331 16.9322 6.40548C17.0646 6.38192 17.1937 6.33643 17.3269 6.31125C17.5795 6.26332 17.858 6.24382 18.1001 6.34861C18.1837 6.38517 18.2609 6.43634 18.325 6.50052C18.632 6.80435 18.589 7.30316 18.524 7.73047C18.0716 10.7086 17.6193 13.6876 17.1661 16.6658C17.1044 17.0744 17.0199 17.5228 16.6975 17.7812C16.4246 17.9997 16.0364 18.0241 15.6994 17.9315C15.3623 17.838 15.0651 17.6423 14.7735 17.4497C13.5643 16.6487 12.3542 15.8477 11.1449 15.0467C10.8574 14.8566 10.5375 14.608 10.5407 14.2628C10.5423 14.0548 10.6666 13.8696 10.7933 13.7047C11.8442 12.3334 13.3604 11.391 14.4885 10.0831C14.6477 9.89869 14.7727 9.56562 14.5543 9.4592C14.4243 9.39584 14.2749 9.48193 14.1563 9.56398C12.6652 10.5998 11.175 11.6364 9.6839 12.6721C9.19743 13.0101 8.6874 13.3578 8.10104 13.4406C7.5764 13.5154 7.04852 13.3692 6.54093 13.2197C6.11537 13.0946 5.69061 12.9662 5.26749 12.8338C5.04253 12.7639 4.81025 12.6884 4.63646 12.53C4.46266 12.3716 4.36278 12.1051 4.46755 11.8939C4.53333 11.7615 4.66084 11.6778 4.79484 11.6096L4.79645 11.6104Z"
                      fill="#383D38"
                    />
                  </svg>
                </span>
              </a>
              <a
                href="https://www.instagram.com/officiallanepact"
                target="blank"
              >
                <span className="flex items-center justify-center">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.0158 24.0063H11.963C5.36277 24.0063 -0.00708008 18.6349 -0.00708008 12.0328V11.9799C-0.00708008 5.37777 5.36277 0.00634766 11.963 0.00634766H12.0158C18.616 0.00634766 23.9859 5.37777 23.9859 11.9799V12.0328C23.9859 18.6349 18.616 24.0063 12.0158 24.0063ZM11.963 0.818723C5.81025 0.818723 0.805056 5.82538 0.805056 11.9799V12.0328C0.805056 18.1873 5.81025 23.194 11.963 23.194H12.0158C18.1685 23.194 23.1737 18.1873 23.1737 12.0328V11.9799C23.1737 5.82538 18.1685 0.818723 12.0158 0.818723H11.963Z"
                      fill="#383D38"
                    />
                    <path
                      d="M15.5761 5.12988H8.40327C6.42166 5.12988 4.80957 6.74245 4.80957 8.72464V15.2886C4.80957 17.2708 6.42166 18.8834 8.40327 18.8834H15.5761C17.5577 18.8834 19.1698 17.2708 19.1698 15.2886V8.72464C19.1698 6.74245 17.5577 5.12988 15.5761 5.12988ZM6.07732 8.72464C6.07732 7.4419 7.12091 6.39799 8.40327 6.39799H15.5761C16.8584 6.39799 17.902 7.4419 17.902 8.72464V15.2886C17.902 16.5714 16.8584 17.6153 15.5761 17.6153H8.40327C7.12091 17.6153 6.07732 16.5714 6.07732 15.2886V8.72464Z"
                      fill="#383D38"
                    />
                    <path
                      d="M11.9897 15.3496C13.8325 15.3496 15.3325 13.8499 15.3325 12.0058C15.3325 10.1617 13.8333 8.66211 11.9897 8.66211C10.1462 8.66211 8.64697 10.1617 8.64697 12.0058C8.64697 13.8499 10.1462 15.3496 11.9897 15.3496ZM11.9897 9.93103C13.134 9.93103 14.0647 10.862 14.0647 12.0067C14.0647 13.1513 13.134 14.0823 11.9897 14.0823C10.8454 14.0823 9.91472 13.1513 9.91472 12.0067C9.91472 10.862 10.8454 9.93103 11.9897 9.93103Z"
                      fill="#383D38"
                    />
                    <path
                      d="M15.6421 9.20223C16.1383 9.20223 16.5428 8.7985 16.5428 8.30132C16.5428 7.80415 16.1391 7.40039 15.6421 7.40039C15.1451 7.40039 14.7415 7.80415 14.7415 8.30132C14.7415 8.7985 15.1451 9.20223 15.6421 9.20223Z"
                      fill="#383D38"
                    />
                  </svg>
                </span>
              </a>
              <a href="https://x.com/lanepact" target="blank">
                <span className="flex items-center justify-center">
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.0087 24.0063H12.9559C6.35569 24.0063 0.98584 18.6349 0.98584 12.0327V11.9799C0.98584 5.37778 6.35569 0.00634766 12.9559 0.00634766H13.0087C19.6089 0.00634766 24.9788 5.37778 24.9788 11.9799V12.0327C24.9788 18.6349 19.6089 24.0063 13.0087 24.0063ZM12.9559 0.818723C6.80317 0.818723 1.79798 5.82539 1.79798 11.9799V12.0327C1.79798 18.1873 6.80317 23.194 12.9559 23.194H13.0087C19.1614 23.194 24.1666 18.1873 24.1666 12.0327V11.9799C24.1666 5.82539 19.1614 0.818723 13.0087 0.818723H12.9559Z"
                      fill="#383D38"
                    />
                    <path
                      d="M6.09336 5.66211L11.4388 12.811L6.06006 18.6236H7.27096L11.9805 13.5348L15.7854 18.6236H19.9054L14.2594 11.0725L19.2662 5.66211H18.0553L13.7185 10.3487L10.2141 5.66211H6.09416H6.09336ZM7.87356 6.5541H9.76584L18.1235 17.7316H16.2313L7.87356 6.5541Z"
                      fill="#383D38"
                    />
                  </svg>
                </span>
              </a>

              <a
                href="https://www.linkedin.com/company/lanepact"
                target="blank"
              >
                <span className="flex items-center  justify-center">
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.0016 24.0063H12.9488C6.34861 24.0063 0.97876 18.6349 0.97876 12.0327V11.9799C0.97876 5.37778 6.34861 0.00634766 12.9488 0.00634766H13.0016C19.6018 0.00634766 24.9717 5.37778 24.9717 11.9799V12.0327C24.9717 18.6349 19.6018 24.0063 13.0016 24.0063ZM12.9488 0.818722C6.7961 0.818722 1.7909 5.8254 1.7909 11.9799V12.0327C1.7909 18.1873 6.7961 23.194 12.9488 23.194H13.0016C19.1544 23.194 24.1596 18.1873 24.1596 12.0327V11.9799C24.1596 5.8254 19.1544 0.818722 13.0016 0.818722H12.9488Z"
                      fill="#383D38"
                    />
                    <path
                      d="M6.91303 8.11972C6.60767 7.8362 6.45581 7.48526 6.45581 7.0677C6.45581 6.65014 6.60848 6.28375 6.91303 5.99941C7.2184 5.7159 7.61147 5.57373 8.09307 5.57373C8.57467 5.57373 8.95231 5.7159 9.25687 5.99941C9.56223 6.28293 9.71409 6.63958 9.71409 7.0677C9.71409 7.49582 9.56142 7.8362 9.25687 8.11972C8.9515 8.40324 8.56411 8.5454 8.09307 8.5454C7.62203 8.5454 7.2184 8.40324 6.91303 8.11972ZM9.45746 9.7461V18.4385H6.71163V9.7461H9.45746Z"
                      fill="#383D38"
                    />
                    <path
                      d="M18.5981 10.6047C19.1967 11.2546 19.4955 12.1466 19.4955 13.2823V18.2849H16.8878V13.6349C16.8878 13.0622 16.7392 12.617 16.4427 12.3002C16.1463 11.9833 15.7467 11.8241 15.2465 11.8241C14.7462 11.8241 14.3466 11.9825 14.0502 12.3002C13.7537 12.617 13.6051 13.0622 13.6051 13.6349V18.2849H10.9819V9.72169H13.6051V10.8574C13.8707 10.4788 14.2289 10.1799 14.6788 9.95971C15.1287 9.73956 15.6347 9.62988 16.1975 9.62988C17.1996 9.62988 18.0004 9.95483 18.5981 10.6047Z"
                      fill="#383D38"
                    />
                  </svg>
                </span>
              </a>
              <a href="https://www.facebook.com/Lanepact" target="blank">
                <span className=" flex items-center justify-center">
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.9945 24.0063H12.9418C6.34152 24.0063 0.97168 18.6349 0.97168 12.0328V11.98C0.97168 5.37779 6.34152 0.00634766 12.9418 0.00634766H12.9945C19.5948 0.00634766 24.9646 5.37779 24.9646 11.98V12.0328C24.9646 18.6349 19.5948 24.0063 12.9945 24.0063ZM12.9418 0.818723C6.78901 0.818723 1.78382 5.8254 1.78382 11.98V12.0328C1.78382 18.1873 6.78901 23.194 12.9418 23.194H12.9945C19.1473 23.194 24.1525 18.1873 24.1525 12.0328V11.98C24.1525 5.8254 19.1473 0.818723 12.9945 0.818723H12.9418Z"
                      fill="#383D38"
                    />
                    <path
                      d="M14.5824 9.3155V11.8412H17.7058L17.2112 15.2434H14.5824V23.082C14.0553 23.1551 13.516 23.1933 12.9686 23.1933C12.3368 23.1933 11.7163 23.1429 11.1121 23.0455V15.2434H8.23145V11.8412H11.1121V8.7509C11.1121 6.83369 12.6657 5.27881 14.5832 5.27881V5.28043C14.5888 5.28043 14.5937 5.27881 14.5994 5.27881H17.7066V8.22123H15.6763C15.0729 8.22123 14.5832 8.71109 14.5832 9.31468L14.5824 9.3155Z"
                      fill="#383D38"
                    />
                  </svg>
                </span>
              </a>
              <a href="https://www.tiktok.com/@lanepacthq" target="blank">
                <span className=" flex items-center justify-center">
                  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.9877 24.0063H12.9349C6.33469 24.0063 0.964844 18.6349 0.964844 12.0328V11.9799C0.964844 5.37777 6.33469 0.00634766 12.9349 0.00634766H12.9877C19.5879 0.00634766 24.9578 5.37777 24.9578 11.9799V12.0328C24.9578 18.6349 19.5879 24.0063 12.9877 24.0063ZM12.9349 0.818723C6.78218 0.818723 1.77698 5.82538 1.77698 11.9799V12.0328C1.77698 18.1873 6.78218 23.194 12.9349 23.194H12.9877C19.1405 23.194 24.1457 18.1873 24.1457 12.0328V11.9799C24.1457 5.82538 19.1405 0.818723 12.9877 0.818723H12.9349Z" fill="#383D38" />
                    <path d="M18.5645 8.801V10.9904C18.182 10.953 17.685 10.8661 17.1351 10.6646C16.4172 10.4014 15.8828 10.0415 15.5328 9.76286V14.1879L15.5239 14.174C15.5296 14.2618 15.5328 14.3511 15.5328 14.4413C15.5328 16.6388 13.7453 18.4277 11.5477 18.4277C9.35001 18.4277 7.5625 16.6388 7.5625 14.4413C7.5625 12.2438 9.35001 10.4542 11.5477 10.4542C11.7629 10.4542 11.974 10.4712 12.1803 10.5046V12.6622C11.9821 12.5915 11.7694 12.5534 11.5477 12.5534C10.5073 12.5534 9.66025 13.3999 9.66025 14.4413C9.66025 15.4828 10.5073 16.3293 11.5477 16.3293C12.588 16.3293 13.4351 15.482 13.4351 14.4413C13.4351 14.4023 13.4342 14.3633 13.4318 14.3243V5.72534H15.6189C15.627 5.91056 15.6343 6.09742 15.6424 6.28264C15.6571 6.6474 15.787 6.99754 16.0136 7.28431C16.2792 7.62144 16.6714 8.01299 17.222 8.32576C17.7378 8.6174 18.2218 8.74333 18.5645 8.80263V8.801Z" fill="#383D38" />
                  </svg>

                </span>
              </a>
            </div>
            <a className="text-gray-300 text-sm lg:ps-3" href="/">
              info@lanepact.org
            </a>
          </div>

          <div className="flex flex-col md:flex-row justify-between gap-4">
            <a href="/" className="text-gray-300 text-sm">
              Home
            </a>
            <a href="/community" className="text-gray-300 text-sm">
              Community
            </a>
            <a href="/request-a-feature" className="text-gray-300 text-sm">
              Request a feature
            </a>
            <a href="/privacy" className="text-gray-300 text-sm">
              Privacy policy
            </a>
            <a href="/terms" className="text-gray-300 text-sm">
              Terms of service
            </a>
          </div>
        </div>
        <hr className="border border-gray-300/40" />
        <div className="mt-8">
          <p className="text-gray-300 text-sm">
            ©2024 Lanepact. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
const AdminMoreIcon = () => {
    return (
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.25 8C3.425 8 2.75 8.675 2.75 9.5C2.75 10.325 3.425 11 4.25 11C5.075 11 5.75 10.325 5.75 9.5C5.75 8.675 5.075 8 4.25 8Z" fill="#838594" />
            <path d="M14.75 8C13.925 8 13.25 8.675 13.25 9.5C13.25 10.325 13.925 11 14.75 11C15.575 11 16.25 10.325 16.25 9.5C16.25 8.675 15.575 8 14.75 8Z" fill="#838594" />
            <path d="M9.5 8C8.675 8 8 8.675 8 9.5C8 10.325 8.675 11 9.5 11C10.325 11 11 10.325 11 9.5C11 8.675 10.325 8 9.5 8Z" fill="#838594" />
        </svg>

    )
}

export default AdminMoreIcon
const UntitledIcon = () => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 2.5H10C6.72077 2.5 5.08116 2.5 3.91891 3.31382C3.48891 3.6149 3.1149 3.98891 2.81382 4.41891C2 5.58116 2 7.22077 2 10.5C2 13.7792 2 15.4188 2.81382 16.5811C3.1149 17.0111 3.48891 17.3851 3.91891 17.6862C5.08116 18.5 6.72077 18.5 10 18.5H14C17.2792 18.5 18.9188 18.5 20.0811 17.6862C20.5111 17.3851 20.8851 17.0111 21.1862 16.5811C22 15.4188 22 13.7792 22 10.5" stroke="#1A1C1F" stroke-width="1.5" stroke-linecap="round" />
            <path d="M18.8295 3.25418C19.1276 2.93123 19.2766 2.76976 19.435 2.67557C19.8171 2.4483 20.2876 2.44124 20.6761 2.65693C20.8372 2.74632 20.9908 2.90325 21.298 3.2171C21.6053 3.53096 21.7589 3.68789 21.8464 3.85237C22.0575 4.24925 22.0506 4.72992 21.8281 5.12028C21.7359 5.28206 21.5779 5.43431 21.2617 5.7388L17.5003 9.36172C16.9012 9.93875 16.6016 10.2273 16.2272 10.3735C15.8528 10.5197 15.4413 10.509 14.6181 10.4874L14.5062 10.4845C14.2556 10.478 14.1303 10.4747 14.0574 10.392C13.9846 10.3094 13.9945 10.1817 14.0144 9.92647L14.0252 9.78786C14.0812 9.06942 14.1092 8.7102 14.2495 8.38729C14.3898 8.06438 14.6318 7.80219 15.1158 7.2778L18.8295 3.25418Z" stroke="#1A1C1F" stroke-width="1.5" stroke-linejoin="round" />
            <path d="M14.6557 22.5L14.2369 22.0811C13.2926 21.1369 13.0585 19.6944 13.6557 18.5" stroke="#1A1C1F" stroke-width="1.5" stroke-linecap="round" />
            <path d="M9.00051 22.5L9.41937 22.0811C10.3636 21.1369 10.5977 19.6944 10.0005 18.5" stroke="#1A1C1F" stroke-width="1.5" stroke-linecap="round" />
            <path d="M7 22.5H17" stroke="#1A1C1F" stroke-width="1.5" stroke-linecap="round" />
            <path d="M8 10.5H15" stroke="#1A1C1F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}

export default UntitledIcon
import React from "react";

const Message = () => {
  return (
    <div>
      <svg
        width="17"
        height="15"
        viewBox="0 0 19 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.8334 8.91668C17.8334 8.50719 17.829 7.68099 17.8202 7.27029C17.7657 4.71568 17.7385 3.43837 16.7959 2.49218C15.8533 1.54599 14.5414 1.51302 11.9177 1.4471C10.3006 1.40647 8.69944 1.40647 7.08237 1.44709C4.45863 1.51301 3.14676 1.54597 2.20415 2.49217C1.26154 3.43836 1.2343 4.71566 1.17982 7.27027C1.16231 8.09168 1.16231 8.90824 1.17983 9.72964C1.2343 12.2843 1.26154 13.5616 2.20415 14.5078C3.14676 15.4539 4.45863 15.4869 7.08238 15.5528C7.75134 15.5696 8.41758 15.5795 9.08335 15.5824"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1.16669 3.5L6.92754 6.77052C9.03224 7.96538 9.9678 7.96538 12.0725 6.77052L17.8334 3.5"
          stroke="white"
          stroke-width="1.5"
          stroke-linejoin="round"
        />
        <path
          d="M17.8334 13.0833L11.1667 13.0833M17.8334 13.0833C17.8334 12.4998 16.1714 11.4096 15.75 11M17.8334 13.0833C17.8334 13.6669 16.1714 14.7571 15.75 15.1667"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
};

export default Message;

const AdminFeatureRequestIcon = () => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 16.8245C2 14.4856 2 13.3162 2.53647 12.4624C2.81621 12.0172 3.19267 11.6407 3.63789 11.361C4.49167 10.8245 5.66111 10.8245 8 10.8245H16C18.3389 10.8245 19.5083 10.8245 20.3621 11.361C20.8073 11.6407 21.1838 12.0172 21.4635 12.4624C22 13.3162 22 14.4856 22 16.8245C22 19.1634 22 20.3329 21.4635 21.1866C21.1838 21.6319 20.8073 22.0083 20.3621 22.2881C19.5083 22.8245 18.3389 22.8245 16 22.8245H8C5.66111 22.8245 4.49167 22.8245 3.63789 22.2881C3.19267 22.0083 2.81621 21.6319 2.53647 21.1866C2 20.3329 2 19.1634 2 16.8245Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M20 10.8245C20 9.42439 20 8.72433 19.7275 8.18955C19.4878 7.71914 19.1054 7.33669 18.635 7.09701C18.1002 6.82452 17.4001 6.82452 16 6.82452H8C6.59987 6.82452 5.8998 6.82452 5.36502 7.09701C4.89462 7.33669 4.51217 7.71914 4.27248 8.18955C4 8.72433 4 9.42439 4 10.8245" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M18 6.82452C18 4.93891 18 3.9961 17.4142 3.41031C16.8284 2.82452 15.8856 2.82452 14 2.82452H10C8.11438 2.82452 7.17157 2.82452 6.58579 3.41031C6 3.9961 6 4.93891 6 6.82452" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M15 14.8245C15 15.9291 14.1046 16.8245 13 16.8245H11C9.89543 16.8245 9 15.9291 9 14.8245" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
        </svg>
    )
}


export default AdminFeatureRequestIcon
const AdminsIcon = () => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.729 2.48523C15.6955 2.48523 16.479 3.26873 16.479 4.23523C16.479 5.20173 17.2625 5.98523 18.229 5.98523H18.8183C20.0296 5.98523 21.0294 6.91177 20.983 8.12213C20.9021 10.235 20.4909 13.5661 18.8891 16.4852C18.4665 17.2554 17.8925 18.0283 17.2551 18.7647C15.2752 21.0522 14.2852 22.196 12.0001 22.196C9.71504 22.196 8.72508 21.0522 6.74517 18.7647C6.10775 18.0283 5.53377 17.2554 5.11115 16.4852C3.55733 13.6535 3.12384 10.6448 3.02535 8.6939C2.96444 7.48734 3.96268 6.56856 5.17077 6.56856H5.47964C6.60722 6.56856 7.5213 5.65448 7.5213 4.5269C7.5213 3.39931 8.43539 2.48523 9.56297 2.48523H14.729Z" stroke="currentColor" stroke-width="1.5" />
            <path d="M10.6901 9.46839C11.1024 8.15751 12.8977 8.15751 13.3101 9.46839C13.4945 10.0546 14.0233 10.4515 14.6201 10.4515C15.9544 10.4515 16.5091 12.2153 15.4297 13.0255C14.9469 13.3878 14.7449 14.03 14.9293 14.6163C15.3416 15.9271 13.8892 17.0172 12.8097 16.207C12.3269 15.8447 11.6732 15.8447 11.1905 16.207C10.111 17.0172 8.65855 15.9271 9.07088 14.6163C9.25528 14.03 9.05327 13.3878 8.57051 13.0255C7.49103 12.2153 8.04581 10.4515 9.38012 10.4515C9.97685 10.4515 10.5057 10.0546 10.6901 9.46839Z" stroke="currentColor" stroke-width="1.5" />
        </svg>

    )
}

export default AdminsIcon